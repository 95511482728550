import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { PageBody } from '../components/common/PageBody'
import Comments from '../components/Comments/Comments'
import { SEO } from '../components/SEO'
import { generateJsonLd } from '../components/util'

const description = `Need someone to talk to or ask for advice? Whether it's personal, career-related, or about web development like Angular, React, or TypeScript, I'm here for you. With 10 years of experience in software engineering, especially in frontend development, I've progressed from software engineer to senior, lead, and now engineering manager. I believe my insights can provide you with a different perspective.`

export default function Chat() {
  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
      </Helmet>
      <SEO pageTitle="Chat">
        <script type="application/ld+json">
          {generateJsonLd('Chat', description, 'https://trungvose.com/chat/')}
        </script>
      </SEO>
      <PageBody title="Chat">
        <>
          <h4 style={{ marginTop: '1.2rem' }}>
            <p>{description}</p>
            <p>
              In the past decade, I've also been grateful to work and live in
              Singapore 🇸🇬 and be an active member of the community, including
              Singapore Computer Society, JuniorDevSG, MentoringSG, and more.
              So, let me know if you have any questions about Singapore and the
              vibrant tech community here.
            </p>
          </h4>
          <h3 style={{ textAlign: 'center', marginTop: 0 }}>
            <a href="/about">Who am I?</a>
          </h3>
          <p>
            If you want to chat, let's{' '}
            <a
              target="_blank"
              href="https://calendly.com/trungvose/ama-w-trung"
            >
              book a convenient time
            </a>{' '}
            . I'm happy to share my knowledge in any way possible.
          </p>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/trungvose/ama-w-trung?hide_event_type_details=1"
            style={{ minWidth: '320px', height: '630px', marginTop: '24px' }}
          ></div>
          <hr />
          <p>
            I am living in GMT +8 timezone and if you can't find a slot, please
            contact me at <a href="https://twitter.com/trungvose">@trungvose</a>
          </p>
        </>
        <Comments />
      </PageBody>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ChatPageQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          email
          twitter
        }
      }
    }
  }
`
