import React from 'react'

export function PageBody({ children, title }) {
  return (
    <div className="content">
      <div className="content__inner">
        <div className="page">
          {title && <h1 className="page__title">{title}</h1>}
          <div className="page__body">{children}</div>
        </div>
      </div>
    </div>
  )
}
